import React, {useEffect, useState} from "react";
import Search from "../../../Img/search-lg.svg";
import styled from "styled-components";
import "./Students.scss";
import {OperatorStudentsTable} from "../../../components/ListTable/OperatorListTabel/OperatorStudentsTable";
import StudentInfoModal from "../../General/StudentInfoModal/StudentInfoModal";
import {useApiServer} from "../../General/ApiServer";
import {Pagination} from "@mui/material";

export const Students = ({isOpen}) => {
    const {getData} = useApiServer();
    const [students, setStudents] = useState([]);
    const [totalStudentCount, setTotalStudentCount] = useState(0);
    const [page, setPage] = useState(1);
    const [totalPageCount, setTotalPageCount] = useState(1);
    const [applicationId, setApplicationId] = useState();
    const [studentId, setStudentId] = useState();
    const role = "ROLE_OPERATOR";

    const [isOpenModal, setIsOpenModal] = useState(false);

    const sendReqForGetAll = async (pageR = page) => {
        getData(`student/my-students?page=${pageR - 1}&size=10`)
            .then((r) => {
                setStudents(r.data.content);
                setTotalStudentCount(r.data.totalElements);
                setTotalPageCount(r.data.totalPages);
            })
            .catch(err => {
                console.log(err)
            });
    };

    const searchRequest = async (text) => {
        await getData(`student/search/C_NEW/${text}`)
            .then((r) => {
                setStudents(r.data);
            })
            .catch(err => {
                console.log(err)
            });
    };

    const handleStudentId = (id = null) => {
        if (id) {
            setStudentId(id);
        }
        setIsOpenModal(true);
    };

    const handlePageChange = async (event, page) => {
        setPage(page);
        await sendReqForGetAll(page);
    };

    const handleSearchText = async (e) => {
        const value = e.target.value;
        if (value && value.length > 3) {
            await searchRequest(value);
        } else if (value.length === 0) {
            await sendReqForGetAll();
        }
    };
    const closeModalFunc = () => {
        setIsOpenModal(false);
        setStudentId(null);
        setApplicationId(null);
    };

    useEffect(() => {
        sendReqForGetAll();
    }, []);

    return (
        <div
            style={{width: isOpen ? "80%" : "100%"}}
            className="containerH students__box"
        >
            <div className="home_header">
                <h2>Students</h2>
                <div className="op_big">
                    <button
                        onClick={() => setIsOpenModal(true)}
                        className="control_header_button me-5"
                    >
                        Add Student
                    </button>
                    <label>
                        <img src={Search} alt={"Search"}/>
                        <input
                            onChange={handleSearchText}
                            type="text"
                            name="input"
                            placeholder="Search"
                        />
                    </label>
                </div>
            </div>
            <div className="home_span"></div>
            <div
                style={{width: isOpen ? "100%" : "100%"}}
                className="mt-4 d-flex justify-content-between studentsBox"
            >
                <WorkerList style={{width: isOpen ? "100%" : "100%"}}>
                    <OperatorStudentsTable
                        students={students}
                        totalStudent={totalStudentCount}
                        handleStudentId={handleStudentId}
                        role={role}
                    />
                    <div className="pagination">
                        <Pagination
                            // className={`pagination pagination_c ${isOpen ? "open" : "closed"}`}
                            count={totalPageCount}
                            size="large"
                            page={page}
                            onChange={handlePageChange}
                        />
                    </div>
                </WorkerList>
            </div>
            <StudentInfoModal
                modal={isOpenModal}
                setModal={closeModalFunc}
                role={role}
                getAll={sendReqForGetAll}
                applicationId={applicationId}
                studentId={studentId}
                notifications={[]}
            />
        </div>
    );
};

const WorkerList = styled.div`
    position: relative;
    width: 190vh;
    height: 80vh;
    overflow-y: scroll;
    border-radius: 12px;
    border: 1px solid #eaecf0;
    background: #fff;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    @media (max-width: 1822px) {
        height: 78vh;
    }
    @media (max-width: 1708px) {
        height: 76vh;
    }
    @media (max-width: 1608px) {
        height: 73vh;
    }
    @media (max-width: 1508px) {
        height: 70vh;
    }
    @media (max-width: 1308px) {
        height: 66vh;
    }
`;
