import {useEffect, useState} from "react";
import {NavLink, Outlet, Route, Routes, useNavigate} from "react-router-dom";
import HomeLogo from "../../Img/home-logo.svg";
import StudentLogo from "../../Img/students-logo.svg";
import KanbanLogo from "../../Img/kanban.svg";
import LogoMark from "../../Img/Logomark.svg";
import LogoTarget from "../../Img/TargetCRM.png";
import Setting from "../../Img/settings.svg";
import LogOut from "../../Img/log-out-logo.svg";
import ReportLogo from "../../Img/Report.svg";
import {Settings} from "../General/Settings/Settings";
import {Report} from "../General/Report/Report";
import {Home} from "./Home/Home";
import {Students} from "./Students/Students";
import {Kanban} from "../General/Kanban/Kanban";
import {Error, Login} from "../index";
import {useAuth} from "../../Hooks/useAuth";
import DefaultImg from "../../Img/DefaultWorker.png";
import {Sticky} from "../../components/Sticky/Sticky";
import {ModalStartWork} from "../../components/Modal/ModalStartWork";

export const Operator = ({admin, setAdmin}) => {
    const navigate = useNavigate();
    const [isOpen, setOpen] = useState(true);
    const { token } = useAuth();

    const toggle = () => setOpen(!isOpen);

    useEffect(() => {
        if (!token) {
            navigate("/register");
        }
    }, [token, navigate]);

    return (
        <div className="d-flex">
            <Routes>
                <Route path="*" element={<Error />} />
                <Route path="/register" element={<Login />} />
                <Route
                    path="/"
                    element={
                        <OperatorSiteBar toggle={toggle} isOpen={isOpen} admin={admin} />
                    }
                >
                    <Route path="/" element={<Home isOpen={isOpen} />} />
                    <Route path="/kanban" element={<Kanban isOpen={isOpen} />} />
                    <Route
                        path="/mystudents"
                        element={<Students isOpen={isOpen} />}
                    />
                    <Route path="/report" element={<Report isOpen={isOpen} />} />
                    <Route path="/settings" element={<Settings setAdminP={setAdmin} />} />
                </Route>
            </Routes>
        </div>
    );
};

export const OperatorSiteBar = ({isOpen, toggle, admin: {fullName, photo}}) => {
    const navigate = useNavigate();
    const {token, setToken} = useAuth();

    const handleLogout = () => {
        setToken(null);
        navigate("/register");
    };
    return (
        <>
            <div
                className="d-flex admin"
                style={{padding: isOpen ? "0 0 0 19%" : "0 0 0 5%"}}
            >
                <div style={{width: isOpen ? "19%" : "5%"}} className="sidebar">
                    <div>
            <span className="sitebar_div" onClick={toggle}>
              <img
                  style={{display: isOpen ? "0px block" : "5px none"}}
                  src={LogoMark}
                  alt={"Logo mark"}
              />
              <img
                  style={{display: isOpen ? "block" : "none"}}
                  src={LogoTarget}
                  alt={"Logo target"}
              />
            </span>
                        <ul className="sitebar_list">
                            <li className="sitebar_item">
                                <NavLink
                                    to={token ? "/" : "/register"}
                                    className="link"
                                    activeclassName="active"
                                >
                                    <img src={HomeLogo} alt={"Home logo"}/>
                                    <p
                                        style={{display: isOpen ? "block" : "none"}}
                                        className="link_text"
                                    >
                                        Home
                                    </p>
                                </NavLink>
                            </li>
                            <li className="sitebar_item">
                                <NavLink to="/kanban" className="link" activeclassName="active">
                                    <img src={KanbanLogo} alt={"Kanban"}/>
                                    <p
                                        style={{display: isOpen ? "block" : "none"}}
                                        className="link_text"
                                    >
                                        Lead
                                    </p>
                                </NavLink>
                            </li>
                            <li className="sitebar_item">
                                <NavLink
                                    to="/mystudents"
                                    className="link"
                                    activeclassName="active"
                                >
                                    <img src={StudentLogo} alt={"Student logo"}/>
                                    <p
                                        style={{display: isOpen ? "block" : "none"}}
                                        className="link_text"
                                    >
                                        My students
                                    </p>
                                </NavLink>
                            </li>
                            <li className="sitebar_item">
                                <NavLink
                                    to="/report"
                                    className="link"
                                    activeclassName="active"
                                >
                                    <img src={ReportLogo} alt={"Report"}/>
                                    <p
                                        style={{display: isOpen ? "block" : "none"}}
                                        className="link_text"
                                    >
                                        Report
                                    </p>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                    <div className="sidebar_bottom">
                        <div className="sidebar_bottom_top">
                            <p style={{pointerEvents: "none"}}>
                                <Sticky isOpen={isOpen}/>
                            </p>
                            <NavLink className="link" activeclassName="active" to="/settings">
                                <img src={Setting} alt={"Setting"}/>
                                <p style={{display: isOpen ? "block" : "none", margin: "0"}}>
                                    Settings
                                </p>
                            </NavLink>
                        </div>
                        <div className="sidebar_bottom_bot">
                            <div>
                                <img
                                    style={{marginRight: isOpen ? "12px" : "0"}}
                                    src={photo ? "https://target-crm-backend-api.uz/api/v1/attach/open/" + photo : DefaultImg}
                                    alt={"Avatar"}
                                />
                                <span style={{display: isOpen ? "block" : "none"}}>
                  <p>{fullName}</p>
                  <p>Operator</p>
                </span>
                            </div>
                            <NavLink
                                onClick={handleLogout}
                                className={"sidebar__logout"}
                                to={token ? "/" : "/register"}
                                style={{display: isOpen ? "block" : "none"}}
                            >
                                <img src={LogOut} alt={"Logout"}/>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
            <Outlet/>
        </>
    );
};
