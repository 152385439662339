import React, {useEffect, useState} from 'react';
import {useApiServer} from "../../General/ApiServer";
import Search from "../../../Img/search-lg.svg";
import styled from "styled-components";
import {AdminStudentList} from "../../../components/ListTable/ListTable";
import Pagination from "@mui/material/Pagination";
import StudentInfoModal from "../../General/StudentInfoModal/StudentInfoModal";
import {useNavigate, useParams} from "react-router-dom";
import BackImg from "../../../Img/back.png";

function EmployeeStudent({isOpen}) {

    const {getData} = useApiServer();
    const [students, setStudents] = useState([]);
    const [totalElements, setTotalElements] = useState(0);
    const [size] = useState(10);
    const [page, setPage] = useState(1);
    const [isDebt, setIsDebt] = useState(5);
    const [status, setStatus] = useState("ALL");
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [applicationId, setApplicationId] = useState();
    const [studentId, setStudentId] = useState();
    const [admin, setAdmin] = useState({});
    const role = "ROLE_ADMIN";
    const {id} = useParams()
    const navigate = useNavigate();

    const requestApiBySearch = (text) => {
        getData(`student/search-by-employee-id/${id}/${text}`)
            .then((r) => setStudents(r.data))
            .catch(err => {
                console.log(err)
            });
    };

    const backFunc = () => {
        navigate("./..")
    }

    const handleStudentId = (id = null) => {
        if (id) {
            setStudentId(id);
        }
        setIsOpenModal(true);
    };

    const closeModalFunc = () => {
        setIsOpenModal(false);
        setStudentId(null);
        setApplicationId(null);
    };
    const requestApiByFilter = (
        pageP = 1,
        sizeP = 10,
        statusP = "ALL",
        isDebtP = 5
    ) => {

        getData(`student/get-all-by-employee-id?id=${id}&page=${pageP - 1}&size=${sizeP}&status=${statusP}&isDebt=${isDebtP}`)
            .then((res) => {
                setStudents(res.data.content);
                setTotalElements(res.data.totalElements);
            })
            .catch(err => {
                console.log(err)
            });
    };

    useEffect(() => {
        getAdmin()
        setStatus("ALL");
        requestApiByFilter();
    }, []);

    const handleSelectChange = (event) => {
        const prop = event.target.id;
        const value = event.target.value;
        if (prop === "is-debt") {
            setIsDebt(value);
            requestApiByFilter(page, size, status, value);
        }
        if (prop === "status") {
            setStatus(value);
            requestApiByFilter(page, size, value, isDebt);
        }
    };

    const getAdmin = () => {
        getData(`admin/${id}`)
            .then((res) => {
                setAdmin(res.data);
            })
            .catch(err =>
                console.log(err)
            )
    }

    const fakeFunc = () => {
    };

    const handleSearchChange = (event) => {
        let text = event.target.value;

        if (text.length >= 4) {
            requestApiBySearch(text);
        } else {
            requestApiByFilter();
        }
    };

    const handlePageChange = (value) => {
        setPage(value);
        requestApiByFilter(value, size, status, isDebt);
    };

    return (
        <div style={{width: isOpen ? "100%" : "100%"}} className="containerH">
            <div className="home_header">
                <div style={{display: "flex", alignItems: "center", gap: 15}} >
                    <img
                        onClick={() => backFunc()}
                        src={BackImg}
                        alt={"Back img"}
                        style={{
                            width: "30px",
                            height: "30px"
                        }}
                    />
                    <h2>{admin.fullName}</h2>
                </div>
                <div>
                    <select
                        onChange={handleSelectChange}
                        className="header_select"
                        style={{width: "max-content"}}
                        id="is-debt"
                    >
                        <option className="header_option" value={5}>
                            ALL
                        </option>
                        <option className="header_option" value={1}>
                            Debtor
                        </option>
                        <option className="header_option" value={0}>
                            Paid
                        </option>
                    </select>
                    <select
                        onChange={handleSelectChange}
                        className="header_select"
                        id="status"
                        name=""
                    >
                        <option className="header_option" selected disabled hidden>
                            Filter(status)
                        </option>
                        <option className="header_option" value="ALL">
                            All
                        </option>
                        <option className="header_option" value="DRAFT">
                            Draft
                        </option>
                        <option className="header_option" value="COMPLETED">
                            Complete
                        </option>
                        <option className="header_option" value="WAITING">
                            Waiting
                        </option>
                        <option className="header_option" value="SUCCESS">
                            Success
                        </option>
                        <option className="header_option" value="REJECT">
                            Reject
                        </option>
                    </select>
                    <label>
                        <img src={Search} alt={"Search"}/>
                        <input
                            className="search__mode"
                            onChange={handleSearchChange}
                            type="text"
                            name="input"
                            placeholder="Search"
                        />
                    </label>
                </div>
            </div>
            <div className="home_span"></div>
            <div className="mt-4 d-flex justify-content-between student__table">
                <WorkerList style={{width: isOpen ? "100%" : "108%"}}>
                    <AdminStudentList
                        studentList={students}
                        totalCount={totalElements}
                        handleStudentId={handleStudentId}
                        role={role}
                    />
                    <div className="pagination">
                        <Pagination
                            count={Math.ceil(totalElements / size)}
                            size="large"
                            page={page}
                            onChange={(e, value) => handlePageChange(value)}
                        />
                    </div>
                </WorkerList>
            </div>

            <StudentInfoModal
                modal={isOpenModal}
                setModal={closeModalFunc}
                role={role}
                getAll={fakeFunc()}
                applicationId={applicationId}
                studentId={studentId}
                notifications={[]}
            />
        </div>
    );
}

export default EmployeeStudent;

const WorkerList = styled.div`
    position: relative;
    width: 190vh;
    height: 73vh;
    overflow-y: scroll;
    border-radius: 12px;
    border: 1px solid #eaecf0;
    background: #fff;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    @media (max-width: 1822px) {
        height: 78vh;
    }
    @media (max-width: 1708px) {
        height: 76vh;
    }
    @media (max-width: 1608px) {
        height: 73vh;
    }
    @media (max-width: 1508px) {
        height: 70vh;
    }
    @media (max-width: 1308px) {
        height: 66vh;
    }
`;