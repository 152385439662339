import React, {useEffect, useState} from "react";
import "./employee.scss";
import styled from "styled-components";
import Plus from "../../../Img/user-plus.svg";
import {AdminEmployeeTopTable} from "../../../components/ListTable/AdminListTable/AdminEmployeeTopTable";
import {AdminEmployeeTable} from "../../../components/ListTable/AdminListTable/AdminEmployeeTable";
import {ModalAll} from "../../../components/Modal/ModalAll";
import {useApiServer} from "../../General/ApiServer";
import Alert from "../../../components/Alert/Alert";

export const Employee = ({ isOpen }) => {
  const { getData, postData, delData } = useApiServer();
  const [modal, setModal] = useState(false);
  const [topEmployee, setTopEmployee] = useState({
    topConsultants: [0],
    topRegistrars: [0],
  });
  const [employeeList, setEmployeeList] = useState([]);
  const [nameBranch, setNameBranch] = useState([]);
  // alert
  const [alert, setAlert] = useState([]);
  const [showAlert, setShowAlert] = useState(false);

  const getAllEmployee = () => {
    getData("admin/get-all")
      .then((r) => {
        setEmployeeList(r.data)
        console.log(r.data)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData("branch/all")
      .then((r) => setNameBranch(r.data))
      .catch((err) => {
        console.log(err);
      });
    getData("admin/top")
      .then((r) => setTopEmployee(r.data))
      .catch((err) => {
        console.log(err);
      });
    getAllEmployee();
  }, []);

  useEffect(() => {

    const intervalId = setInterval(getAllEmployee, 5000);

    return () => clearInterval(intervalId);
  }, []);

  const addEmployeeWorker = async (evt) => {
    evt.preventDefault();
    setShowAlert(false);

    const data = {
      phone: evt.target.phone.value,
      branchId: evt.target.branchId.value,
      role: evt.target.role.value,
    };

    await postData("admin/create", data)
      .then(() => {
        setAlert(["success", "Successfully created"]);
      })
      .catch(async (err) => {
        console.log(err);

        if (err.response.status === 409) {
          await setAlert(["warning", err.response.data.message]);
        } else if (err.response.status === 400) {
          await setAlert([
            "error",
            "The cells are empty or the data is invalid",
          ]);
        } else {
          await setAlert([
            "error",
            "Please check that the information is correct",
          ]);
        }
      });
    setShowAlert(true);
    await getAllEmployee();
  };

  const deleteEmployeeFunc = async (id) => {
    setShowAlert(false);
    await delData(`admin/${id}`)
      .then(() => {
        setAlert(["success", "Delete successfully"]);
      })
      .catch(() => {
        setAlert(["error", "Cannot deleted"]);
      });
    await getAllEmployee();
    setShowAlert(true);
  };

  const fakeFunc = () => {};

  return (
    <div style={{ width: isOpen ? "80%" : "95%" }} className="containerH emp_b">
      <div className="home_header">
        <h2>Employee</h2>
        <div style={{ display: "flex" }}>
          <button
            onClick={() => setModal(true)}
            className="employe_header_button"
          >
            <img src={Plus} alt={"Plus"} />
            Add
          </button>
        </div>
      </div>
      <div className="home_span"></div>

      <div className="d-flex mt-4 employee__mode">
        <WorkerListTop className="me-3">
          <AdminEmployeeTopTable
            employeeList={topEmployee.topConsultants}
            headerName={"Top 3 Consultant"}
          />
        </WorkerListTop>
        <WorkerListTop className="ms-3">
          <AdminEmployeeTopTable
            employeeList={topEmployee.topRegistrars}
            headerName="Top 3 Registrator"
          />
        </WorkerListTop>
      </div>
      <div className="mt-4 d-flex justify-content-between employee__modes">
        <WorkerList style={{ width: isOpen ? "100%" : "108%" }}>
          <AdminEmployeeTable
            employee={employeeList}
            deleteEmployeeFunc={deleteEmployeeFunc}
          />
        </WorkerList>
      </div>
      <form onSubmit={addEmployeeWorker} className="add_modal_form">
        <ModalAll modal={modal} closeModalP={setModal} setClearInput={fakeFunc}>
          <h3 className="add_modal_title">Add employee</h3>
          <p className="add_modal_text">Please fill in the following fields</p>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label style={{ marginBottom: "16px" }}>
              <p className="add_modal_input_text">Phone number</p>
              <input
                className="add_modal_input"
                name="phone"
                placeholder="+998881234567"
                type="text"
                required
              />
            </label>
            <label style={{ marginBottom: "16px" }}>
              <p className="add_modal_input_text">Role</p>
              <select className="add_modal_input" name="role">
                <option disabled selected>
                  Choose role
                </option>
                <option value="ROLE_ADMIN">Admin</option>
                <option value="ROLE_OPERATOR">Operator</option>
                <option value="ROLE_CONSULTANT">Consultant</option>
                <option value="ROLE_REGISTER">Registrar</option>
              </select>
            </label>
            <label style={{ marginBottom: "16px" }}>
              <p className="add_modal_input_text">Branch</p>
              <select className="add_modal_input" name="branchId">
                <option disabled selected>
                  Choose Branch
                </option>
                {nameBranch.map((branch) => (
                  <option value={branch.id}>{branch.name}</option>
                ))}
              </select>
            </label>
          </div>
          <button
            onClick={() => setModal(false)}
            className="add_modal_button"
            type="submit"
          >
            Add employee
          </button>
        </ModalAll>
      </form>
      {showAlert && <Alert status={alert[0]} message={alert[1]} />}
    </div>
  );
};

const WorkerList = styled.div`
  position: relative;
  width: 190vh;
  overflow-y: scroll;
  border-radius: 12px;
  border: 1px solid #eaecf0;
  background: #fff;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);

  @media (max-width: 2000px) {
    height: 60vh; // Height for screens smaller than 2000px
  }

  @media (max-width: 1802px) {
    height: 58vh; // Height for screens smaller than 1802px
  }
  @media (max-width: 1702px) {
    height: 54vh; // Height for screens smaller than 1802px
  }

  @media (max-width: 1508px) {
    height: 49vh; // Height for screens smaller than 1268px
  }
`;
const WorkerListTop = styled.div`
  /* position: relative; */
  width: 190vh;
  /* max-height: 73vh; */
  /* overflow-y: scroll; */
  border-radius: 12px;
  border: 1px solid #eaecf0;
  background: #fff;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
`;
