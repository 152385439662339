import React, { useState } from 'react';
import Checkmark from "../../Img/accept.png";
import './InstagramPage.scss';

const InstagramPage = () => {
  const initialFormData = {
    name: '',
    surname: '',
    age: '',
    phone: '+998',
    degree: 'BACHELOR',
    tgUsername: '',
    englishLevel: '',
    country: ''
  };

  const [showOverlay, setShowOverlay] = useState(false);
  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (evt) => {
    const { id, value } = evt.target;
    if (id === 'phone') {
      const cleanedValue = value.replace(/[^\d+]/g, ''); // Remove non-numeric characters except '+'
      setFormData({
        ...formData,
        [id]: cleanedValue
      });
    } else {
      setFormData({
        ...formData,
        [id]: value
      });
    }
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setShowOverlay(true);
    const payload = {
      name: formData.name,
      surname: formData.surname,
      age: formData.age,
      phone: formData.phone,
      degree: formData.degree,
      tgUsername: formData.tgUsername,
      englishLevel: formData.englishLevel.toUpperCase(),
      country: formData.country
    };
    console.log('Payload:', payload);
    try {
      const response = await fetch('https://target-crm-backend-api.uz/api/v1/applications/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'accept': '*/*'
        },
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      
      const text = await response.text();
      console.log('Success:', text);

      // Clear the form data
      setFormData(initialFormData);

    } catch (error) {
      console.error('Error:', error);
    } finally {
      setTimeout(() => {
        setShowOverlay(false);
      }, 2000);
    }
  };

  return (
    <div>
      <div className="formik">
        <div className="formik__box">
          <h2 className="formik__title">Target consulting</h2>
          <p className="formik__text">
            Ariza qoldirish uchun quyidagi ma’lumotlarni to’ldiring
          </p>
        </div>
        <form onSubmit={handleSubmit} className="formik__form">
          <label>
            Ism
            <input
              type="text"
              id="name"
              className="form__input"
              placeholder="Ismingizni kiriting"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Familiya
            <input
              type="text"
              id="surname"
              className="form__input"
              placeholder="Familiyangizni kiriting"
              value={formData.surname}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Yosh
            <input
              type="number"
              id="age"
              className="form__input"
              placeholder="Yoshingizni kiriting"
              value={formData.age}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Telefon raqamingiz
            <input
              type="tel"
              id="phone"
              className="form__input"
              value={formData.phone}
              onChange={handleChange}
              placeholder="+998901234567"
              pattern="\+998\d{9,12}"
              minLength="13"
              maxLength="16"
              required
            />
          </label>
          <label>
            Darajangiz (Baklavr, Magistr)
            <select
              id="degree"
              className="form__input"
              value={formData.degree}
              onChange={handleChange}
              required
            >
              <option value="BACHELOR">BACHELOR</option>
              <option value="MASTER">MASTER</option>
            </select>
          </label>
          <label>
            Telegram username
            <input
              type="text"
              id="tgUsername"
              className="form__input"
              placeholder="@Username"
              value={formData.tgUsername}
              onChange={handleChange}
            />
          </label>
          <label>
            Ingliz tili darajangiz
            <input
              type="text"
              id="englishLevel"
              className="form__input"
              placeholder="B2"
              value={formData.englishLevel}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Qaysiz davlatda o’qimoqchisiz?
            <input
              type="text"
              id="country"
              className="form__input"
              placeholder="Davlat nomi"
              value={formData.country}
              onChange={handleChange}
              required
            />
          </label>
          <button className="formik__btn" type="submit">
            Yuborish
          </button>
        </form>
      </div>
      {showOverlay && (
        <div className="animation-overlay show" id="animation-overlay">
          <img src={Checkmark} alt="Checkmark" />
          DONE
        </div>
      )}
    </div>
  );
};

export default InstagramPage;
