import React, {useEffect, useState} from "react";
import Search from "../../../Img/search-lg.svg";
import styled from "styled-components";
import {ConsultantStudentList} from "../../../components/ListTable/ConsultantListTable/ConsultantStudentList";
import {useApiServer} from "../../General/ApiServer";
import {Pagination} from "@mui/material";
import StudentInfoModal from "../../General/StudentInfoModal/StudentInfoModal";

export const Students = ({isOpen , notifications}) => {
    const {getData} = useApiServer();
    const [students, setStudents] = useState([]);
    const [totalStudentCount, setTotalStudentCount] = useState(0);
    const [page, setPage] = useState(1);
    const [status, setStatus] = useState("ALL");
    const [totalPageCount, setTotalPageCount] = useState(1);
    const [applicationId, setApplicationId] = useState();
    const [studentId, setStudentId] = useState();
    const role = "ROLE_CONSULTANT";
    const [isOpenModal, setIsOpenModal] = useState(false);

    const sendReqForGetAll = async (pageR = page, statusR = status) => {
        getData(`student/get-all-by-status?page=${pageR - 1}&size=10&status=${statusR}`)
            .then((r) => {
                setStudents(r.data.content);
                setTotalStudentCount(r.data.totalElements);
                setTotalPageCount(r.data.totalPages);
            })
            .catch(err => {
                console.log(err)
            });
    }

    const searchRequest = async (text, statusR = status) => {
        await getData(`student/search/${statusR}/${text}`)
            .then((r) => {
                setStudents(r.data);
            })
            .catch(err => {
                console.log(err)
            });
    };

    const handleStudentId = (id = null) => {
        if (id) {
            setStudentId(id);
        }
        setIsOpenModal(true);
    };

    const handlePageChange = async (event, pageP) => {
        setPage(pageP);
        await sendReqForGetAll(pageP, status);
    };

    const handleMenuChange = async (prop) => {
        setStatus(prop);
        await sendReqForGetAll(page, prop);
    };

    const handleSearchText = async (e) => {
        const value = e.target.value;
        if (value.length === 0) {
            await sendReqForGetAll();
        } else if (value && value.length > 3) {
            await searchRequest(value);
        }
    };

    const closeModalFunc = () => {
        setIsOpenModal(false);
        setStudentId(null);
        setApplicationId(null);
        console.log("closeModalFunc con");
    };

    useEffect(() => {
        sendReqForGetAll();
    }, []);

    return (
        <div style={{width: "100%"}} className="containerH">
            <div className="home_header">
                <h2>Students</h2>
                <div>
                    <label>
                        <img src={Search} alt="Search"/>
                        <input
                            onChange={handleSearchText}
                            type="text"
                            name="input"
                            placeholder="Search"
                        />
                    </label>
                </div>
            </div>
            <div className="home_span"></div>
            <div className="d-flex justify-content-between">
                <ul style={{width: "max-content"}} className="control_list">
                    <li
                        className="control_item  control_link"
                        style={{
                            color: "#667085",
                            backgroundColor: status === "ALL" ? "#fff" : "#f9fafb",
                        }}
                        onClick={() => handleMenuChange("ALL")}
                    >
                        All
                    </li>
                    <li
                        className="control_item  control_link"
                        style={{
                            color: status === "C_NEW" ? "#6941C6" : "#667085" ,
                            backgroundColor: status === "C_NEW" ? "#F9F5FF" : "#f9fafb",
                        }}
                        onClick={() => handleMenuChange("C_NEW")}
                    >
                        New
                    </li>
                    <li
                        className="control_item control_link"
                        style={{
                            color:status === "DRAFT" ? "#FF9800" : "#667085" ,
                            backgroundColor: status === "DRAFT" ? "#FFF3E0" : "#f9fafb",
                        }}
                        onClick={() => handleMenuChange("DRAFT")}
                    >
                        Draft
                    </li>
                    <li
                        className="control_item control_link"
                        style={{
                            color:status === "COMPLETED" ? "#00FF7F" : "#667085" ,
                            backgroundColor: status === "COMPLETED" ? "#E0FFE8" : "#f9fafb",
                        }}
                        onClick={() => handleMenuChange("COMPLETED")}
                    >
                        Complete
                    </li>
                    <li
                        className="control_item control_link"
                        style={{
                            color:status === "ACTION_NEEDED" ? "#9C27B0" : "#667085" ,
                            width: "max-content",
                            backgroundColor: status === "ACTION_NEEDED" ? "#F3E5F5" : "#f9fafb",
                        }}
                        onClick={() => handleMenuChange("ACTION_NEEDED")}
                    >
                        Action needed
                    </li>
                    <li
                        className="control_item control_link"
                        style={{
                            color:status === "REPEAT" ? "#E91E63" : "#667085" ,
                            backgroundColor: status === "REPEAT" ? "#FCE4EC" : "#f9fafb",
                        }}
                        onClick={() => handleMenuChange("REPEAT")}
                    >
                        Repeat
                    </li>
                </ul>
                <div style={{display: "flex", alignItems: "center"}}>
                    <button
                        style={{
                            alignItems: "center",
                            height: "54px",
                            width: "170px",
                            display: "flex",
                            padding: "10px 14px",
                            border: "1px solid #D0D5DD",
                            borderRadius: "7px",
                            marginRight: "10px",
                            backgroundColor: "#7f56d9",
                            paddingLeft: "35px",
                            color: "var(--Gray-700, #fff)"
                        }}
                        onClick={() => setIsOpenModal(true)}
                    >
                        Add student
                    </button>
                </div>
            </div>
            <div className="home_span"></div>
            <div className="mt-4 d-flex justify-content-between">
                <WorkerList style={{width: isOpen ? "100%" : "108%"}}>
                    <ConsultantStudentList
                        studentList={students}
                        totalStudent={totalStudentCount}
                        handleStudentId={handleStudentId}
                        role={role}
                    />
                    <div className="pagination">
                        <Pagination
                            //   className={`pagination ${isOpen ? "open" : "closed"}`}
                            count={totalPageCount}
                            size="large"
                            page={page}
                            onChange={handlePageChange}
                        />
                    </div>
                </WorkerList>
            </div>
            <StudentInfoModal
                modal={isOpenModal}
                setModal={closeModalFunc}
                role={role}
                getAll={sendReqForGetAll}
                applicationId={applicationId}
                studentId={studentId}
                notifications={notifications}
            />
        </div>
    );
};

const WorkerList = styled.div`
    position: relative;
    width: 190vh;
    height: 70vh;
    overflow-y: scroll;
    border-radius: 12px;
    border: 1px solid #eaecf0;
    background: #fff;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    /* margin-right: 32px; */
    @media (max-width: 1822px) {
        height: 61vh;
    }
    @media (max-width: 1708px) {
        height: 59vh;
    }
    @media (max-width: 1608px) {
        height: 56vh;
    }
    @media (max-width: 1518px) {
        height: 54vh;
    }
    @media (max-width: 1368px) {
        height: 50vh;
    }
`;

export default Students;
