import React from 'react';
import InstagramPage from './pages/Instagram/InstagramPage';
import {Login} from "./pages";

function App() {
    const currentPath = window.location.pathname;

    if (currentPath === '/Instagram') {
        return <InstagramPage/>;
    } else {
        return <Login/>;
    }
}
export default App;


