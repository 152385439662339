import React, {useEffect, useState} from "react";
import Search from "../../../Img/search-lg.svg";
import styled from "styled-components";
import {ConsultantChatListTable} from "../../../components/ListTable/ConsultantListTable/ConsultantChatListTable";
import {useApiServer} from "../ApiServer";
import StudentInfoModal from "../StudentInfoModal/StudentInfoModal";
import {id} from "date-fns/locale";

export const ChatPage = ({isOpen, notifications}) => {
    const [chats, setChats] = useState([]);
    const [role, setRole] = useState("");
    const [studentId, setStudentId] = useState(null);
    const {getData} = useApiServer()


    useEffect(() => {
        // const nLength = parseInt(localStorage.getItem("nLength")) || 0;
        // localStorage.setItem("nLength", notifications.length);

        const storedRole = localStorage.getItem("role");
        setRole(storedRole);

        // if (nLength !== notifications.length) {
        //     console.log("sdfasdasdsd")
        //     console.log(nLength !== notifications.length)
            requestForGet("if");
        // }
    }, []);

    const requestForGet = async (t = "d") => {
        console.log(t)
        let id = localStorage.getItem("id");
        await getData(`room/get-all/${id}`)
            .then(r => setChats(r.data))
            .catch(err => console.log(err));

    }

    const deleteWrongNotification = () => {
      if (notifications.length > 0) {
          notifications.forEach(notification => {
              if (notification.id === id) {

              }
          })
      }
    }

    const closeModalFunc = () => {
        setStudentId(null);
    };

    return (
        <div style={{width: isOpen ? "100%" : "100%"}} className="containerH">
            <div className="home_header">
                <h2>Chat</h2>
                <div>
                    <label>
                        <img src={Search} alt={"Search"}/>
                        <input type="text" name="input" placeholder="Search"/>
                    </label>
                </div>
            </div>
            <div className="home_span"></div>
            <div className="mt-4 d-flex justify-content-between">
                <WorkerList style={{width: isOpen ? "100%" : "108%"}}>
                    <ConsultantChatListTable
                        chats={chats}
                        role={role}
                        setStudentId={setStudentId}
                        notifications={notifications}
                    />
                </WorkerList>
            </div>
            <StudentInfoModal
                modal={studentId != null}
                setModal={closeModalFunc}
                role={(role === "ROLE_REGISTER" ? "ROLE_REGISTRAR" : role)}
                applicationId={null}
                getAll={requestForGet}
                studentId={studentId}
                notifications={notifications}
            />
        </div>
    );
};

const WorkerList = styled.div`
    width: 190vh;
    border-radius: 12px;
    border: 1px solid #eaecf0;
    background: #fff;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
`;
