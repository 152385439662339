import React, {useEffect, useState} from "react";
import {ModalX} from "../../../components/Modal/ModalX";
import {StudentApplication} from "./StudentApplication";
import {StudentInfoDocuments} from "./StudentInfoDocuments";
import {OperatorStudentInfoPersonalDetails} from "./StudentInfoPersonDetails";
import {StudentInfoProgramDetails} from "./StudentInfoProgramDetails";
import {useApiServer} from "../ApiServer";
import "./StudentInfoModal.scss"
import {StudentInfoChat} from "./StudentInfoChat";
import {StudentResult} from "./StudentResult";
import {addDoc, collection} from "firebase/firestore";
import {db} from "../firebase-config";


// Define role-based steps
const roleSteps = {
    ROLE_ADMIN: {
        application: false,
        personalDetails: true,
        programDetails: true,
        documents: true,
        result: true,
        chat: false,
    },
    ROLE_OPERATOR: {
        application: true,
        personalDetails: true,
        programDetails: true,
        documents: true,
        result: false,
        chat: false,
    },
    ROLE_CONSULTANT: {
        application: true,
        personalDetails: true,
        programDetails: true,
        documents: true,
        result: true,
        chat: true,
    },
    ROLE_REGISTRAR: {
        application: false,
        personalDetails: true,
        programDetails: true,
        documents: true,
        result: true,
        chat: true,
    },
};

const StudentInfoModal = ({
                              modal,
                              setModal,
                              role,
                              getAll,
                              applicationId,
                              studentId,
                              notifications
                          }) => {
    const steps = roleSteps[role] || {};
    const initialStep = "Personal details";
    const [activeTab, setActiveTab] = useState(initialStep);
    const [selectedStatus, setSelectedStatus] = useState(null);
    // SELECT INFO
    const [showSelectEmployee, setShowSelectEmployee] = useState(false);
    const [selectName, setSelectName] = useState("");
    const [employee, setEmployee] = useState([]);
    // INFO STUDENT
    const [student, setStudent] = useState({});
    const [application, setApplication] = useState({});
    const {getData, postData, putData} = useApiServer();
    const [isContract, setIsContract] = useState(false)
    // Upload Download
    const [isHiddenBtn, setIsHiddenBtn] = useState(false);
    // Assign
    const [forAssignId, setForAssignedId] = useState();
    const [assignedId, setAssignedId] = useState(null);
    const [requiredField, setRequiredField] = useState(false);


    useEffect(() => {
        const fetchStudentAndApplication = async () => {
            if (studentId) {
                await getData(`student/get-by-id/${studentId}`)
                    .then(async (res1) => {
                        setStudent(res1.data);
                        setIsContract(res1.data.isContract)
                        if (role === "ROLE_OPERATOR") {
                            setAssignedId(res1.data.consultantId || null);
                        } else if (role === "ROLE_CONSULTANT") {
                            setAssignedId(res1.data.registrarId || null);
                        } else if (role === "ROLE_REGISTRAR") {
                            if (res1.data.status === "REPEAT") {
                                setSelectedStatus("REPEAT")
                            } else if (res1.data.status === "WAITING") {
                                setSelectedStatus("WAITING")
                            } else if (res1.data.status === "SUCCESS") {
                                setSelectedStatus("SUCCESS")
                            } else if (res1.data.status === "REJECT") {
                                setSelectedStatus("REJECT")
                            }
                        }
                        if (res1.data.applicationId) {
                            await getData(`applications/get/${res1.data.applicationId}`)
                                .then((res2) => {
                                        setApplication(res2.data);
                                    }
                                )
                                .catch(err => {
                                    console.log(err)
                                });
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    });
            } else if (applicationId) {
                await getData(`applications/get/${applicationId}`)
                    .then(async (res1) => {
                            setApplication(res1.data);
                            if (res1.data.studentId) {
                                await getData(`student/get-by-id/${res1.data.studentId}`)
                                    .then((res2) => {
                                            setStudent(res2.data)
                                            setIsContract(res2.data.isContract)
                                        }
                                    )
                                    .catch(err => {
                                        console.log(err)
                                    });
                            }
                        }
                    )
                    .catch(err => {
                        console.log(err)
                    });
            } else if (applicationId != null) {
                setAssignedId(null)
            }

            const showSelectEmployeeR =
                role === "ROLE_OPERATOR" || role === "ROLE_CONSULTANT";
            if (showSelectEmployeeR) {
                setShowSelectEmployee(showSelectEmployeeR);
                setSelectName(role === "ROLE_OPERATOR" ? "Consultants" : "Registrars");

                const reqRole =
                    role === "ROLE_OPERATOR" ? "ROLE_CONSULTANT" : "ROLE_REGISTER";
                await getData(`admin/all?role=${reqRole}`)
                    .then(res => {
                        setEmployee(res.data);
                    })
                    .catch(err => {
                        console.log(err)
                    });

            }
        };

        fetchStudentAndApplication();

        console.log(steps)
    }, [studentId, applicationId]);

    const handleSelectChange = (event) => {
        setSelectedStatus(event.target.value);
    };

    const handleChangeEmployee = (event) => {
        setForAssignedId(event.target.value);
    };


    const handleIsContractClick = async () => {
        if (studentId) {
            await putData(`student/change-contract/${studentId}/${!isContract}`)
                .catch(err => {
                    console.log(err)
                });
            setIsContract(!isContract);
        }

    };

    const requestForAssign = async () => {
        if (student) {
            if (forAssignId) {

                if (!validatorForRequiredFields() && role === "ROLE_CONSULTANT") {
                    setRequiredField(true)
                    return false;
                }
                await putData(`student/assign-other/${student.id}/${forAssignId}`)
                    .catch(err => {
                        console.log(err)
                    });

                await addDoc(collection(db, forAssignId), {
                    room: student.id,
                });

                return true
            }
        }
        return true;
    };

    const requestForChangeStatus = async (statusR = null) => {
        if (statusR) {
            await putData(`student/change-status` ,{studentId: studentId, status: statusR})
                .catch(err => {
                    console.log(err)
                });
        }
    }

    const closeModalFunc = async () => {
        setStudent({});
        setApplication({});
        setModal();
        setIsHiddenBtn(false);
        setForAssignedId(null);
        setAssignedId(null);
        setSelectedStatus(null);
        setIsContract(false);
        setRequiredField(false);
        console.log("modal close")
    };
    console.log(requiredField)

    const validatorForRequiredFields = () => {
        if (
            (student.passport1 || student.passport2 || student.passport3)
            &&
            (student.languageCertificate1 || student.languageCertificate2 || student.languageCertificate3)
            &&
            (student.diploma1 || student.diploma2 || student.diploma3)
            &&
            (student.dsInfoBox)
        ) {
            return true;
        } else {
            return false;
        }
    }

    const onSave = async () => {


        const data = {
            name: student.name || null,
            surname: student.surname || null,
            email: student.email || null,
            phone: student.phone || null,
            contractNumber: student.contractNumber || null,
            totalPrice: student.totalPrice || null,
            paidPrice: student.paidPrice || null,
            debtPrice: student.debtPrice || null,
            degree: student.degree || null,
            type: student.type || null,
            intake: student.intake || null,
            countryInsuranceId: student.countryInsuranceId || null,
            countryId: student.countryId || null,
            passport1: student.passport1 || null,
            passport2: student.passport2 || null,
            passport3: student.passport3 || null,
            languageCertificate1: student.languageCertificate1 || null,
            languageCertificate2: student.languageCertificate2 || null,
            languageCertificate3: student.languageCertificate3 || null,
            diploma1: student.diploma1 || null,
            diploma2: student.diploma2 || null,
            diploma3: student.diploma3 || null,
            recommendation1: student.recommendation1 || null,
            recommendation2: student.recommendation2 || null,
            recommendation3: student.recommendation3 || null,
            personalStatement1: student.personalStatement1 || null,
            personalStatement2: student.personalStatement2 || null,
            personalStatement3: student.personalStatement3 || null,
            cv: student.cv || null,
            photos: student.photos || null,
            dsInfoBox: student.dsInfoBox || null,
            other1: student.other1 || null,
            other2: student.other2 || null,
            other3: student.other3 || null,
            result1: student.result1 || null,
            result2: student.result2 || null,
            result3: student.result3 || null,
            description: student.description || null,
            operatorId: student.operatorId || null,
            consultantId: student.consultantId || null,
            registrarId: student.registrarId || null,
            applicationId: applicationId || null,
            isContract: isContract,
        };

        if (studentId) {
            await putData(`student/update/${studentId}`, data)
                .catch(err => {
                    console.log(err)
                });
            if (role === "ROLE_REGISTRAR") {
                await requestForChangeStatus(selectedStatus)
            } else {
                await requestForAssign()
            }
        } else {
            await postData("student/create", data)
                .catch(err => {
                    console.log(err)
                });
        }

        await getAll()
        let res = await requestForAssign();
        if (!res) {
            return
        }
        await closeModalFunc();

    };

    const onSaveTest = async (init = {}) => {


        const data = {
            name: init.name || null,
            surname: init.surname || null,
            email: init.email || null,
            phone: init.phone || null,
            contractNumber: init.contractNumber || null,
            totalPrice: init.totalPrice || null,
            paidPrice: init.paidPrice || null,
            debtPrice: init.debtPrice || null,
            degree: init.degree || null,
            type: init.type || null,
            intake: init.intake || null,
            countryInsuranceId: init.countryInsuranceId || null,
            countryId: init.countryId || null,
            passport1: init.passport1 || null,
            passport2: init.passport2 || null,
            passport3: init.passport3 || null,
            languageCertificate1: init.languageCertificate1 || null,
            languageCertificate2: init.languageCertificate2 || null,
            languageCertificate3: init.languageCertificate3 || null,
            diploma1: init.diploma1 || null,
            diploma2: init.diploma2 || null,
            diploma3: init.diploma3 || null,
            recommendation1: init.recommendation1 || null,
            recommendation2: init.recommendation2 || null,
            recommendation3: init.recommendation3 || null,
            personalStatement1: init.personalStatement1 || null,
            personalStatement2: init.personalStatement2 || null,
            personalStatement3: init.personalStatement3 || null,
            cv: init.cv || null,
            photos: init.photos || null,
            dsInfoBox: init.dsInfoBox || null,
            other1: init.other1 || null,
            other2: init.other2 || null,
            other3: init.other3 || null,
            result1: student.result1 || null,
            result2: student.result2 || null,
            result3: student.result3 || null,
            description: student.description || null,
            operatorId: init.operatorId || null,
            consultantId: init.consultantId || null,
            registrarId: init.registrarId || null,
            applicationId: applicationId || null,
            isContract: isContract,
        };

        if (studentId) {
            await putData(`student/update/${studentId}`, data)
                .catch(err => {
                    console.log(err)
                });
        } else {
            await postData("student/create", data)
                .catch(err => {
                    console.log(err)
                });
        }

        await requestForAssign();
    };

    const renderContent = () => {
        switch (activeTab) {
            case "Application":
                return <StudentApplication application={application}/>;
            case "Personal details":
                return (
                    <OperatorStudentInfoPersonalDetails
                        detail={student}
                        setDetail={setStudent}
                        role={role}
                    />
                );
            case "Program details":
                return (
                    <StudentInfoProgramDetails
                        detail={student}
                        setDetailProp={setStudent}
                        role={role}


                    />
                );
            case "Documents":
                return (
                    <StudentInfoDocuments
                        detail={student}
                        setDetail={setStudent}
                        role={role}
                        onSave={onSaveTest}
                        isHiddenBtn={setIsHiddenBtn}
                        requiredFields={requiredField}
                    />
                );
            case "Result":
                return (
                    <StudentResult
                        detail={student}
                        setDetail={setStudent}
                        role={role}
                        onSave={onSaveTest}
                        isHiddenBtn={setIsHiddenBtn}
                    />
                );
            case "Chat":
                return (
                    <StudentInfoChat student={student} notifications={notifications}/>
                );
            default:
                return (
                    <OperatorStudentInfoPersonalDetails
                        detail={student}
                        setDetail={setStudent}
                        role={role}
                    />
                );
        }
    };

    const handleChangeAssignRole = async (role) => {

        if (role === "Consultants") {
            await getData(`admin/all?role=ROLE_REGISTER`)
                .then(res => {
                    setEmployee(res.data);
                    setSelectName("Registrars")
                })
                .catch(err => {
                    console.log(err)
                });
        } else if (role === "Registrars") {
            await getData(`admin/all?role=ROLE_OPERATOR`)
                .then(res => {
                    setEmployee(res.data);
                    setSelectName("Consultants")
                })
                .catch(err => {
                    console.log(err)
                });
        }

    }


    return (
        <ModalX modal={modal} setModal={closeModalFunc} student={student}>
            <div className="d-flex justify-content-between">
                <ul style={{padding: "0"}} className="control_list d-flex">
                    {((steps.application && student.applicationId) || (steps.application && applicationId)) && (
                        <li className="control_item">
                            <span
                                onClick={() => setActiveTab("Application")}
                                className={`modal_def m-0 mx-1 ${
                                    activeTab === "Application" ? "modal_active" : ""
                                }`}
                            >
                                <p className="modal_defaout">Application</p>
                            </span>
                        </li>
                    )}
                    {steps.personalDetails && (
                        <li className="control_item">
                            <span
                                onClick={() => setActiveTab("Personal details")}
                                className={`modal_def m-0 mx-1 ${
                                    activeTab === "Personal details" ? "modal_active" : ""
                                }`}
                            >
                                <p className="modal_defaout">Personal details</p>
                            </span>
                        </li>
                    )}
                    {steps.programDetails && (
                        <li className="control_item">
                            <span
                                onClick={() => setActiveTab("Program details")}
                                className={`modal_def m-0 mx-1 ${
                                    activeTab === "Program details" ? "modal_active" : ""
                                }`}
                            >
                                <p className="modal_defaout">Program details</p>
                            </span>
                        </li>
                    )}

                    {steps.documents && (
                        <li className="control_item">
                            <span
                                onClick={() => setActiveTab("Documents")}
                                className={`modal_def m-0 mx-1 ${
                                    activeTab === "Documents" ? "modal_active" : ""
                                }`}
                            >
                                <p className="modal_defaout"
                                   style={{...(requiredField && {color: "red"})}}> Documents < /p>
                                       </span>
                        </li>
                    )}
                    {steps.result && (role === "ROLE_REGISTRAR" || role === "ROLE_ADMIN" || ((student.result1 || student.result2 || student.result3) && role === "ROLE_CONSULTANT")) && (
                        <li className="control_item">
                            <span
                                onClick={() => setActiveTab("Result")}
                                className={`modal_def m-0 mx-1 ${
                                    activeTab === "Result" ? "modal_active" : ""
                                }`}
                            >
                                <p className="modal_defaout">Result</p>
                            </span>
                        </li>
                    )}
                    {steps.chat && (
                        <li className="control_item">
                            <span
                                onClick={() => setActiveTab("Chat")}
                                className={`modal_def m-0 mx-1 ${
                                    activeTab === "Chat" ? "modal_active" : ""
                                }`}
                            >
                                <p className="modal_defaout">Chat</p>
                            </span>
                        </li>
                    )}
                </ul>
                <div className="d-flex">
                    <div className="contract_div me-4" hidden={role === "ROLE_REGISTRAR"}>
                        <button
                            className={
                                isContract
                                    ? "contract_button contract_colorX"
                                    : "contract_button contract_colorY"
                            }
                            onClick={role === "ROLE_ADMIN" ? undefined : () => handleIsContractClick()}
                        >
                            Contract
                        </button>
                    </div>
                    {showSelectEmployee && (
                        <select
                            style={{
                                width: "223px",
                                padding: "2px 10px",
                                marginTop: "20px",
                                fontSize: "16px",
                                borderRadius: "5px",
                                border: "1px solid #d0d5dd",
                                alignItems: "center",
                            }}
                            id=""
                            name=""
                            disabled={assignedId != null || role === "ROLE_ADMIN"}
                            onChange={handleChangeEmployee}
                        >
                            <option selected={assignedId === null} disabled hidden>
                                {selectName}
                            </option>
                            {employee.map((e) => (
                                <option
                                    selected={assignedId !== null && e.id === assignedId}
                                    className="header_option"
                                    value={e.id}
                                >
                                    {e.fullName}
                                </option>


                            ))}
                        </select>
                    )}
                    <div
                        hidden={role === "ROLE_CONSULTANT" || student.consultantId || student.registrarId}
                        onClick={() => handleChangeAssignRole(selectName)}
                        style={{
                            cursor: "pointer",
                            marginTop: "22px",
                            display: "flex",
                            alignItems: "center"
                        }}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-0.5 -0.5 16 16" fill="none"
                             id="Repeat-Circle--Streamline-Majesticons" height="30" width="30">
                            <desc>Repeat Circle Streamline Icon: https://streamlinehq.com</desc>
                            <path fill="#7f56d9" fill-rule="evenodd"
                                  d="M14.375 7.5c0 -3.796875 -3.078125 -6.875 -6.875 -6.875S0.625 3.703125 0.625 7.5s3.078125 6.875 6.875 6.875 6.875 -3.078125 6.875 -6.875zM6.6918750000000005 4.191875a0.625 0.625 0 0 0 -0.8837499999999999 -0.8837499999999999l-1.25 1.25a0.625 0.625 0 0 0 0 0.8837499999999999l1.25 1.25a0.625 0.625 0 0 0 0.8837499999999999 -0.8837499999999999L6.50875 5.625H8.75a0.625 0.625 0 0 1 0.625 0.625v0.625a0.625 0.625 0 1 0 1.25 0v-0.625a1.875 1.875 0 0 0 -1.875 -1.875h-2.24125l0.18312499999999998 -0.18312499999999998zM5.625 8.125a0.625 0.625 0 1 0 -1.25 0v0.625a1.875 1.875 0 0 0 1.875 1.875h2.24125l-0.18312499999999998 0.18312499999999998a0.625 0.625 0 0 0 0.8837499999999999 0.8837499999999999l1.25 -1.25a0.625 0.625 0 0 0 0 -0.8837499999999999l-1.25 -1.25a0.625 0.625 0 0 0 -0.8837499999999999 0.8837499999999999l0.18312499999999998 0.18312499999999998H6.25a0.625 0.625 0 0 1 -0.625 -0.625v-0.625z"
                                  clip-rule="evenodd" stroke-width="1"></path>
                        </svg>
                    </div>
                    {role === "ROLE_REGISTRAR" && (
                        <select
                            style={{
                                width: "auto",
                                padding: "2px 10px",
                                marginTop: "20px",
                                fontSize: "16px",
                                borderRadius: "5px",
                                border: "1px solid #d0d5dd",
                                alignItems: "center",
                            }}
                            id=""
                            name=""
                            onChange={handleSelectChange}
                        >


                            <option selected={selectedStatus == null} disabled hidden>
                                Choose
                            </option>
                            <option selected={selectedStatus === "WAITING"} className="header_option"
                                    value="WAITING">
                                Waiting
                            </option>
                            <option selected={selectedStatus === "ACTION_NEEDED"} className="header_option"
                                    value="ACTION_NEEDED">
                                Action needed
                            </option>
                            <option selected={selectedStatus === "SUCCESS"} className="header_option"
                                    value="SUCCESS">
                                Success
                            </option>
                            <option selected={selectedStatus === "REJECT"} className="header_option"
                                    value="REJECT">
                                Reject
                            </option>
                            <option selected={selectedStatus === "REPEAT"} className="header_option"
                                    value="REPEAT">
                                Repeat
                            </option>
                        </select>
                    )}
                </div>
            </div>
            {renderContent()}
            <span hidden={
                role === "ROLE_ADMIN"
                || (assignedId != null && student.status !== "REPEAT")
                || (assignedId != null && role === "ROLE_OPERATOR")
                || isHiddenBtn
                /*|| activeTab === "Chat"*/}
                  className="setting_person_form_bottom">
                <button
                    disabled={isHiddenBtn}
                    type="reset"
                    onClick={() => closeModalFunc()}
                >
                    Cancel
                </button>
                <button disabled={isHiddenBtn} onClick={() => onSave()} type="submit">
                    Save changes
                </button>
            </span>
        </ModalX>
    );
};

export default StudentInfoModal;
