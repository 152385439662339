import React, {useEffect, useState} from "react";
import FileDow from "../../../Img/file-dow.svg";
import {useApiServer} from "../ApiServer";

export const StudentResult = ({
                                  detail,
                                  setDetail,
                                  role,
                                  onSave,
                                  isHiddenBtn
                              }) => {
    const {delData, postFile, putFile, downloadData} = useApiServer();
    const [isUpload, setIsUpload] = useState(false);
    const [isDelete, setIsDelete] = useState(false);


    useEffect(() => {
        const initial = async () => {
            await setIsUpload(
                (role === "ROLE_ADMIN")
                || (role === "ROLE_OPERATOR" && detail.consultantId)
                || detail.id == null
            )
            await setIsDelete(
                (role === "ROLE_ADMIN")
                || (role === "ROLE_OPERATOR" && detail.consultantId)
            )
        }

        initial()
    }, []);

    const handleOnChange = async (e, prop) => {
        isHiddenBtn(true);
        const file = e.target.files[0];
        const fileName = file.name.split('.')[0];

        const value = detail[prop] || null;
        if (value) {
            return await putFile(file, fileName, value)
                .then(async (r) => {
                    setDetail((prevDetail) => ({
                        ...prevDetail,
                        [prop]: r,
                    }));
                    await onSave({...detail, [prop]: value})
                    isHiddenBtn(false);
                })
                .catch(err => {
                    console.log(err)
                });
        } else {
            return await postFile(file, fileName)
                .then(async (r) => {
                    setDetail((prevDetail) => ({
                        ...prevDetail,
                        [prop]: r,
                    }))
                    await onSave({...detail, [prop]: r})
                    isHiddenBtn(false);
                })
                .catch(err => {
                    console.log(err)
                });
        }
    };

    const handleOnDelete = async (e, prop) => {
        e.preventDefault();
        e.stopPropagation();

        setIsDelete(true)

        // Show the hidden button
        isHiddenBtn(true);

        const value = detail[prop] || null;
        if (value) {
            try {

                // Perform the delete operation
                await delData(`attach/delete/${value}`)
                    .then(async () => {
                            await onSave({...detail, [prop]: null})
                            await setDetail({...detail, [prop]: null})
                        }
                    )
                    .catch(err => {
                        console.log(err)
                    });

            } catch (error) {
                console.error("Error deleting data:", error);
                // Handle error appropriately (e.g., show an error message)
            } finally {
                // Hide the button
                isHiddenBtn(false);
                setIsDelete(false);
            }
        } else {
            // Hide the button if there is no value
            isHiddenBtn(false);
            setIsDelete(false);
        }
    }

    const handleOnDownload = async (e, prop) => {


        const value = detail[prop] || null;
        if (value) {
            await downloadData(value)
                .catch(err => {
                    console.log(err)
                });
        }
    };

    return (
        <form className="students_forms" action="#" method="post">
            <h5 className="students_forms_title mt-3 mb-0">Documents</h5>
            <div className="students_forms_height">
                <div className={"students_forms_box1 mt-2 d-flex"}>
                    <h6 className="students_forms_boxTitle">Result Documents</h6>
                    <div className={"students_forms_inputBox justify-content-start d-flex flex-wrap wrapper"}>
                        {[1, 2, 3].map((num) => (
                            <div key={num}>
                                <div>Document-{num}</div>
                                <div className="file-input-container">
                                    <input
                                        type="text"
                                        value={detail[`result${num}`] || ""}
                                        readOnly
                                        className="file-name"
                                        placeholder="download"
                                    />
                                    <input
                                        type="file"
                                        id={`resultFileInput${num}`}
                                        className="file-input"
                                        onChange={(e) => handleOnChange(e, `result${num}`)}
                                    />
                                    <label
                                        htmlFor={`resultFileInput${num}`}
                                        className="file-label"
                                        style={{pointerEvents: isUpload ? "none" : "auto"}}
                                    >
                                        <img
                                            style={{width: "20px"}}
                                            className="file-icon"
                                            src={FileDow}
                                            alt="File Icon"
                                        />
                                    </label>
                                    <div
                                        className="file-label-down"
                                        onClick={(e) => handleOnDownload(e, `result${num}`)}
                                        style={{
                                            cursor: "pointer",
                                            pointerEvents: detail[`result${num}`] ? "auto" : "none"
                                        }}

                                    >
                                        <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M17.5 12.5V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V12.5M14.1667 8.33333L10 12.5M10 12.5L5.83333 8.33333M10 12.5V2.5"
                                                stroke="#344054"
                                                strokeWidth="1.66667"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </div>
                                    <div
                                        className="file-label-svg"
                                        onClick={(e) => handleOnDelete(e, `result${num}`)}
                                        style={{
                                            cursor: "pointer",
                                            pointerEvents: detail[`result${num}`] && !isDelete ? "auto" : "none"
                                        }}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            className="file-icon"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                        >
                                            <path
                                                d="M13.3333 5.00033V4.33366C13.3333 3.40024 13.3333 2.93353 13.1517 2.57701C12.9919 2.2634 12.7369 2.00844 12.4233 1.84865C12.0668 1.66699 11.6001 1.66699 10.6667 1.66699H9.33333C8.39991 1.66699 7.9332 1.66699 7.57668 1.84865C7.26308 2.00844 7.00811 2.2634 6.84832 2.57701C6.66667 2.93353 6.66667 3.40024 6.66667 4.33366V5.00033M8.33333 9.58366V13.7503M11.6667 9.58366V13.7503M2.5 5.00033H17.5M15.8333 5.00033V14.3337C15.8333 15.7338 15.8333 16.4339 15.5608 16.9686C15.3212 17.439 14.9387 17.8215 14.4683 18.0612C13.9335 18.3337 13.2335 18.3337 11.8333 18.3337H8.16667C6.76654 18.3337 6.06647 18.3337 5.53169 18.0612C5.06129 17.8215 4.67883 17.439 4.43915 16.9686C4.16667 16.4339 4.16667 15.7338 4.16667 14.3337V5.00033"
                                                stroke="#475467"
                                                strokeWidth="1.66667"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </form>
    );
};
