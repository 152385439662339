import React, {useEffect, useState} from "react";
import {NavLink, Outlet, Route, Routes, useNavigate} from "react-router-dom";
import HomeImg from "../../Img/home-logo.svg";
import StudentsImg from "../../Img/students-logo.svg";
import Chat from "../../Img/Chat.svg";
import LogoMark from "../../Img/Logomark.svg";
import LogoTarget from "../../Img/TargetCRM.png";
import ReportLogo from "../../Img/Report.svg";
import Setting from "../../Img/settings.svg";
import LogOut from "../../Img/log-out-logo.svg";
import {Settings} from "../General/Settings/Settings";
import {Home} from "./Home/Home";
import {StudentsRegistrar} from "./Students/Students";
import {Error, Login} from "../index";
import {useAuth} from "../../Hooks/useAuth";
import DEfaultImg from "../../Img/DefaultWorker.png";
import {Sticky} from "../../components/Sticky/Sticky";
import {ChatPage} from "../General/Chat/ChatPage";
import {Report} from "../General/Report/Report";
import {useApiServer} from "../General/ApiServer";

export const Registrar = ({admin, setAdmin, notifications}) => {
    const navigate = useNavigate();
    const [isOpen, setOpen] = useState(true);
    const {token} = useAuth();
    const {putData} = useApiServer()
    const toggle = () => setOpen(!isOpen);

    useEffect(() => {
        if (!token) {
            navigate("/register");
        }
    }, [token, navigate, notifications]);

    useEffect(() => {
        const sendRequest = () => {
            putData('admin/update-status')
                .then(response => {
                    console.log('Request sent', response.data);
                })
                .catch(error => {
                    console.error('Error sending request', error);
                });
        };

        const intervalId = setInterval(sendRequest, 10000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className="d-flex">
            <Routes>
                <Route path="*" element={<Error/>}/>
                <Route path="/register" element={<Login/>}/>
                <Route
                    path="/"
                    element={
                        <RegistrarSiteBar
                            toggle={toggle}
                            isOpen={isOpen}
                            setOpen={setOpen}
                            admin={admin}
                            notifications={notifications}
                        />
                    }
                >
                    <Route
                        path="/"
                        element={<Home isOpen={isOpen} setOpen={setOpen}/>}
                    />
                    <Route
                        path="/students"
                        element={<StudentsRegistrar isOpen={isOpen} setOpen={setOpen} notifications={notifications}/>}
                    ></Route>
                    <Route
                        path="/chat"
                        element={<ChatPage isOpen={isOpen} setOpen={setOpen} notifications={notifications}/>}
                    />
                    <Route path="/report" element={<Report isOpen={isOpen}/>}/>
                    <Route
                        path="/settings"
                        element={
                            <Settings
                                isOpen={isOpen}
                                setOpen={setOpen}
                                setAdminP={setAdmin}
                            />
                        }
                    />
                </Route>
            </Routes>
        </div>
    );
};



export const RegistrarSiteBar = ({isOpen, toggle, admin, notifications}) => {
    const navigate = useNavigate();
    const {token, setToken} = useAuth();

    const handleLogout = () => {
        setToken(null);
        navigate("/register");
    };


    return (
        <>
            <div
                className="d-flex admin"
                style={{padding: isOpen ? "0 0 0 19%" : "0 0 0 5%"}}
            >
                <div style={{width: isOpen ? "19%" : "5%"}} className="sidebar">
                    <div>
            <span className="sitebar_div" onClick={toggle}>
              <img
                  style={{display: isOpen ? "0px block" : "5px none"}}
                  src={LogoMark}
                  alt={"Logo"}
              />
              <img
                  style={{display: isOpen ? "block" : "none"}}
                  src={LogoTarget}
                  alt={"Target logo"}
              />
            </span>
                        <ul className="sitebar_list">
                            <li className="sitebar_item">
                                <NavLink
                                    to={token ? "/" : "/register"}
                                    className="link"
                                    activeclassName="active"
                                >
                                    <img src={HomeImg} alt={"Home img"}/>
                                    <p
                                        style={{display: isOpen ? "block" : "none"}}
                                        className="link_text"
                                    >
                                        Home
                                    </p>
                                </NavLink>
                            </li>
                            <li className="sitebar_item">
                                <NavLink
                                    to="/students"
                                    className="link"
                                    activeclassName="active"
                                >
                                    <img src={StudentsImg} alt={"Student img"}/>
                                    <p
                                        style={{display: isOpen ? "block" : "none"}}
                                        className="link_text"
                                    >
                                        Students
                                    </p>
                                </NavLink>
                            </li>
                            <li className="sitebar_item">
                                <NavLink
                                    // style={{ pointerEvents: "none" }}
                                    to="/chat"
                                    className="link"
                                    activeclassName="active"
                                >
                                    <img src={Chat} alt={"Chat"}/>
                                    <p
                                        style={{display: isOpen ? "block" : "none"}}
                                        className="link_text"
                                    >
                                        Chat {notifications.length > 0 && <span
                                        style={{
                                            marginLeft: "120px",
                                            background: "#e58686",
                                            width: "30px",
                                            height: "30px",
                                            display: "inline-block",
                                            padding: "3px 9px",
                                            border: "#f60505 solid 1px",
                                            borderRadius: "50%",
                                        }}
                                        className={"side-bar-notification-count"}>{notifications.length}</span>}
                                    </p>
                                </NavLink>
                            </li>
                            <li className="sitebar_item">
                                <NavLink to="/report" className="link" activeclassName="active">
                                    <img src={ReportLogo} alt={"Report"}/>
                                    <p
                                        style={{display: isOpen ? "block" : "none"}}
                                        className="link_text"
                                    >
                                        Report
                                    </p>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                    <div className="sidebar_bottom">
                        <div className="sidebar_bottom_top">
                            <p style={{pointerEvents: "none"}}>
                                <Sticky isOpen={isOpen}/>
                            </p>
                            <NavLink className="link" activeclassName="active" to="/settings">
                                <img src={Setting} alt={"Setting"}/>
                                <p style={{display: isOpen ? "block" : "none", margin: "0"}}>
                                    Settings
                                </p>
                            </NavLink>
                        </div>
                        <div className="sidebar_bottom_bot">
                            <div>
                                <img
                                    style={{marginRight: isOpen ? "12px" : "0"}}
                                    src={admin.photo ? `https://target-crm-backend-api.uz/api/v1/attach/open/` + admin.photo : DEfaultImg}
                                    alt={"Admin img"}
                                />
                                <span style={{display: isOpen ? "block" : "none"}}>
                  <p>{admin.fullName}</p>
                  <p>Registrar</p>
                </span>
                            </div>
                            <NavLink
                                onClick={handleLogout}
                                className={"sidebar__logout"}
                                to={token ? "/" : "/register"}
                                style={{display: isOpen ? "block" : "none"}}
                            >
                                <img src={LogOut} alt={"Logout"}/>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
            <Outlet/>
        </>
    );
};
