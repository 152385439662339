import {useEffect, useRef, useState} from "react";
import DefaultWorker from "../../../Img/DefaultWorker.png";
import {db} from "../firebase-config";
import {
    collection,
    doc,
    addDoc,
    deleteDoc,
    serverTimestamp,
    query,
    orderBy,
    onSnapshot,
} from "firebase/firestore";
import {useApiServer} from "../ApiServer";

export const StudentInfoChat = ({student, notifications}) => {
    const {getData, postData} = useApiServer();
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState("");
    const [receiver, setReceiver] = useState({});
    const [role, setRole] = useState("");

    const [sId, setSId] = useState("");
    const [rId, setRId] = useState("");

    const scrollRef = useRef();
    const textareaRef = useRef();

    useEffect(() => {
        const s = localStorage.getItem("id");
        const role = localStorage.getItem("role");

        setSId(s);

        if (role === "ROLE_CONSULTANT") {
            setRId(student.registrarId);
            setRole("Registrar");
            requestGetById(student.registrarId);
        } else {
            setRId(student.consultantId);
            requestGetById(student.consultantId);
            setRole("Consultant");
        }

        if (student.id) {
            scrollToBottom();
            const q = query(collection(db, student.id), orderBy("createdAt"));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                let messages = [];
                querySnapshot.forEach((doc) => {
                    messages.push({...doc.data(), id: doc.id});
                });

                setMessages(messages);
                scrollToBottom();
                let arr = notifications.filter((item) => item.room === student.id);
                if (arr.length > 0) {
                    deleteNotifications(arr, s).then(() => {
                    });
                }
            });
            return () => unsubscribe();
        }
    }, [student.id, student.consultantId, student.registrarId, notifications]);

    const requestGetById = (id) => {
        getData(`admin/${id}`)
            .then((r) => setReceiver(r.data))
            .catch((err) => console.log(err));
    };

    const deleteNotifications = async (arr, s) => {
        for (const arrElement of arr) {
            try {
                const docRef = doc(db, s, arrElement.id);
                await deleteDoc(docRef);
            } catch (error) {
                console.error("Error deleting document:", error);
            }
        }
    };

    const sanitizeMessage = (message) => {
        const element = document.createElement('div');
        element.innerText = message;
        return element.innerHTML.replace(/ /g, '&nbsp;').replace(/\n/g, '<br/>');
    };

    const breakLongMessage = (message, maxLineLength = 125) => {
        let result = '';
        let currentLine = '';
        let words = message.split('&nbsp;'); // Split message by non-breaking spaces

        for (let i = 0; i < words.length; i++) {
            let word = words[i] + '&nbsp;'; // Re-add the space after each word

            if ((currentLine + word).length > maxLineLength) {
                result += currentLine.trim() + '<br/>';
                currentLine = word;
            } else {
                currentLine += word;
            }
        }

        result += currentLine.trim(); // Add the remaining part of the message
        return result;
    };

    const handleSubmit = async () => {
        if (newMessage === "") return;

        let text = sanitizeMessage(newMessage);
        setNewMessage("");

        await addDoc(collection(db, student.id), {
            text: text,
            createdAt: serverTimestamp(),
            sId,
            rId,
            room: student.id,
        });

        await addDoc(collection(db, rId), {
            sId,
            rId,
            room: student.id,
        });


        await postData("room/add", {
            id: null,
            createdDate: null,
            roomId: student.id,
            senderId: sId,
            receiverId: rId,
        });

        scrollToBottom();
    };

    const scrollToBottom = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollIntoView({behavior: "smooth"});
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter" && !event.shiftKey) {
            event.preventDefault();
            handleSubmit();
        }
    };

    useEffect(() => {
        const handleTabKey = (event) => {
            if (event.key === "Tab") {
                event.preventDefault();
                const {selectionStart, selectionEnd} = textareaRef.current;
                const value = textareaRef.current.value;

                textareaRef.current.value =
                    value.substring(0, selectionStart) +
                    "\t" +
                    value.substring(selectionEnd);

                textareaRef.current.selectionStart =
                    textareaRef.current.selectionEnd =
                        selectionStart + 1;

                setNewMessage(textareaRef.current.value);
            }
        };

        const textarea = textareaRef.current;
        textarea.addEventListener("keydown", handleTabKey);
        textarea.addEventListener("keydown", handleKeyDown);

        return () => {
            textarea.removeEventListener("keydown", handleTabKey);
            textarea.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    const formatDate = (timestamp) => {
        if (!timestamp) return ""; // Check if timestamp exists
        const date = timestamp.toDate();
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const formatTime = (timestamp) => {
        if (!timestamp) return ""; // Check if timestamp exists
        const date = timestamp.toDate();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    };

    const groupMessagesByDate = (messages) => {
        const groupedMessages = [];
        let lastDate = null;

        messages.forEach((message) => {
            const messageDate = formatDate(message.createdAt);
            if (messageDate !== lastDate) {
                groupedMessages.push({type: 'date', date: messageDate});
                lastDate = messageDate;
            }
            groupedMessages.push({type: 'message', ...message});
        });

        return groupedMessages;
    };

    const groupedMessages = groupMessagesByDate(messages);

    return (
        <form
            className="students_forms"
            style={{height: "470px"}}
            action="#"
            method="post"
            onSubmit={(e) => e.preventDefault()}
        >
            <div className="chat-window">
                <div className="chat-header">
                    <img
                        className="chat_header_img"
                        src={
                            receiver.photo
                                ? "https://target-crm-backend-api.uz/api/v1/attach/open/" + receiver.photo
                                : DefaultWorker
                        }
                        alt="Worker"
                    />
                    <div className="chat_employee">
                        <p className="chat_employee_fullname">{receiver.fullName}</p>
                        <p style={{marginLeft: "10px"}} className="chat_employee_role">
                            {role}
                        </p>
                    </div>

                </div>


                <div className="chat-body">
                    <div className="message">
                        <ul className={"message-body"}>
                            {groupedMessages.map((item, index) => (
                                item.type === 'date' ? (
                                    <li className="date-separator" key={index}>
                                        {item.date}
                                    </li>
                                ) : (
                                    <li
                                        className={`message-item ${
                                            item.sId === sId ? "sent" : "received"
                                        }`}
                                        key={item.id}
                                    >
                                        <span dangerouslySetInnerHTML={{__html: breakLongMessage(item.text)}}></span>
                                        <span className="message-time">
                                            {item.createdAt ? formatTime(item.createdAt) : ''}
                                        </span>
                                    </li>
                                )
                            ))}
                            <div ref={scrollRef} style={{width: "10px"}}></div>
                            <div ref={scrollRef} style={{width: "3px"}}></div>
                        </ul>
                    </div>
                    <div className="chat-footer">
                        <textarea
                            ref={textareaRef}
                            value={newMessage}
                            style={{
                                overflowY: newMessage.split("\n").length > 4 ? "auto" : "inherit",
                            }}
                            onChange={(e) => setNewMessage(e.target.value)}
                            placeholder="Send a message"
                            rows="1"
                            onKeyDown={handleKeyDown}
                        />
                        <div className="d-flex align-items-center justify-content-end">
                            <button
                                onClick={handleSubmit}
                                style={{
                                    "background-color": "#7F56D9",
                                    "color": "white",
                                    "padding": "6px 23px",
                                    "border-radius": "10px",
                                    "font-weight": "bolder"
                                }}
                            >Send
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};
