import React, { useEffect, useRef, useState } from "react";
import Plus from "../../../Img/user-plus.svg";
import Search from "../../../Img/search-lg.svg";
import styled from "styled-components";
import {
  ApplicationNewTable,
  ApplicationOldTable,
} from "../../../components/ListTable/ListTable";
import "./application.css";
import "./application.scss";
import DefaultWorkerImg from "../../../Img/DefaultWorker.png";
import { useApiServer } from "../../General/ApiServer";

export const Application = ({ isOpen }) => {
  const { getData, putData } = useApiServer();
  const [applications, setApplications] = useState([]);
  const [modal, setModal] = useState(false);
  const [tickList, setTickList] = useState([]);
  const [assignId, setAssignId] = useState();
  const [operatorList, setOperatorList] = useState([]);
  const overlyClose = useRef();
  const [activeBtn, setActiveBtn] = useState("NEW");
  const [activeAssignBtn, setActiveAssignBtn] = useState("ROLE_OPERATOR");

  const getAllApplications = async (status = activeBtn) => {
    await getData(`applications/get-all-by-status/${status}`)
      .then((r) => {
        setApplications(r.data);
        setActiveBtn(status);
      })
      .catch((err) => {
        setApplications([]);
        setActiveBtn(status);
        console.log(err);
      });
  };

  const searchRequest = async (text) => {
    await getData(`applications/get-all-by-status/${activeBtn}/${text}`)
      .then((r) => {
        setApplications(r.data);
      })
      .catch((err) => {
        setApplications([]);
        console.log(err);
      });
  };

  const getAllOperators = (activeRole = "ROLE_OPERATOR") => {
    getData(`admin/all?role=${activeRole}`)
      .then((r) => setOperatorList(r.data))
      .catch((err) => {
        setOperatorList([]);
        console.log(err);
      });
  };

  const assignToOperator = () => {
    putData("applications/assign-to-employee", {
      applicationIdList: tickList,
      employeeId: assignId,
    })
      .then(async () => {
        await getAllApplications();
        getAllOperators();
        setTickList([]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllApplications();
    getAllOperators();
  }, []);

  const closeAssign = (e) => {
    if (e.target === overlyClose.current) {
      setModal(!modal);
    }
  };

  useEffect(() => {
    function handleCloseModal(evt) {
      console.log(evt);
      if (evt.code === "Escape") {
        setModal(false);
      }
    }

    if (modal) {
      window.addEventListener("keyup", handleCloseModal);
    }
    return () => window.removeEventListener("keyup", handleCloseModal);
  }, [modal]);

  const handleRadioChange = (e) => {
    setAssignId(e.target.id);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    assignToOperator();
    setTickList([]);
    setAssignId(null);
    setModal(false);
  };

  const handleSearchText = async (e) => {
    const value = e.target.value;
    if (value.length > 1) {
      await searchRequest(value);
    } else if (value.length === 0) {
      await getAllApplications();
    }
  };

  const handleRole = (activeRole) => {
    setActiveAssignBtn(activeRole)

    getAllOperators(activeRole)
  }

  return (
    <div
      style={{ width: isOpen ? "100%" : "100%" }}
      className="containerH application"
    >
      <div className="home_header">
        <h2>Application</h2>
        <div>
          <label>
            <img src={Search} alt={"Search"} />
            <input
              className="app__inp"
              type="text"
              name="input"
              placeholder="Search"
              onChange={handleSearchText}
            />
          </label>
        </div>
      </div>
      <div className="home_span"></div>
      <ul className="control_list">
        <li
          className="control_item control_link"
          style={{
            color: "#667085",
            backgroundColor: activeBtn === "NEW" ? "#fff" : "#f9fafb",
          }}
          onClick={() => getAllApplications("NEW")}
        >
          New application
        </li>
        <li
          className="control_item control_link"
          style={{
            color: "#667085",
            backgroundColor: activeBtn === "OLD" ? "#fff" : "#f9fafb",
          }}
          onClick={() => getAllApplications("OLD")}
        >
          Old application
        </li>
      </ul>
      <div className="home_header mt-3" style={{ position: "relative" }}>
        <h3 className="control_name">{Application}</h3>
        {activeBtn === "NEW" && (
          <button
            style={{ border: "none", outline: "none" }}
            ref={overlyClose}
            onClick={closeAssign}
            className="employe_header_button"
          >
            <img src={Plus} className="mx-2" alt={"Plus icon"} />
            Assign
          </button>
        )}
      </div>
      <div className="mt-4 d-flex justify-content-between bigBox">
        <WorkerList style={{ width: isOpen ? "100%" : "108%" }}>
          {activeBtn === "NEW" ? (
            <ApplicationNewTable
              applicationList={applications}
              setTickList={setTickList}
              tickList={tickList}
            />
          ) : (
            <ApplicationOldTable applicationList={applications} />
          )}
        </WorkerList>
        {modal && (
          <Modal className={`${!modal && "d-none"}`} style={{ zIndex: "100" }}>
            {/*<h2 style={{ fontSize: "14px", lineHeight: "20px" }}></h2>*/}
            <ul className={"assign-button-box"}>
              <li
                  className={(activeAssignBtn === "ROLE_OPERATOR") ? "active": ""}
                  onClick={()=> handleRole("ROLE_OPERATOR")}
              >Operators</li>
              <li
                  className={(activeAssignBtn === "ROLE_CONSULTANT") ? "active": ""}
                  onClick={()=> handleRole("ROLE_CONSULTANT")}
              >Consultant</li>
            </ul>
            <form onSubmit={handleSubmit}>
              <ModalBox>
                {operatorList.map(({fullName, id, photo}) => (
                  <ModalLabel
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "10px 6px",
                      background: "#F9FAFB",
                      marginTop: "15px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <img
                        className="modalls"
                        style={{ marginRight: "8px" }}
                        src={
                          photo
                            ? "https://target-crm-backend-api.uz/api/v1/attach/open/" +
                              photo
                            : DefaultWorkerImg
                        }
                        alt="avatar"
                        width={24}
                        height={24}
                      />
                      <span>{fullName}</span>
                    </div>
                    <input
                      type="radio"
                      name="name"
                      id={id}
                      onChange={handleRadioChange}
                    />
                  </ModalLabel>
                ))}
                <button
                  style={{
                    position: "absolute",
                    top: "87%",
                    padding: "10px 134px",
                    fontSize: "14px",
                    lineHeight: "20px",
                    background: "#7F56D9",
                    borderRadius: "10px",
                    color: "white",
                    fontWeight: "600",
                  }}
                  hidden={assignId == null}
                  type="submit"
                  /*onClick={() => setModal(false)}*/
                >
                  Assign
                </button>
              </ModalBox>
            </form>
          </Modal>
        )}
      </div>
    </div>
  );
};

const Modal = styled.div`
  position: absolute;
  right: 0;
  top: -33px;
  background-color: #f9fafb;
  padding: 16px 9px 50px 9px;
  border: 2px solid #eaecf0;
  border-radius: 10px;
`;

const ModalBox = styled.div`
  height: 300px;
  overflow-y: scroll;
  scrollbar-color: #eaecf0 transparent;
  scrollbar-width: thin;
`;

const ModalLabel = styled.label`
  display: flex;
  width: 308px;
  font-size: 16px;
  line-height: normal;
  margin-bottom: 10px;
  margin-right: 5px;
  background-color: white !important;
  border: 2px solid #dfdfdf;
  border-radius: 10px;
`;

const WorkerList = styled.div`
  position: relative;
  width: 190vh;
  height: 70vh;
  overflow-y: scroll;
  border-radius: 12px;
  border: 1px solid #eaecf0;
  background: #fff;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  @media (max-width: 1822px) {
    height: 66vh;
  }
  @media (max-width: 1708px) {
    height: 63vh;
  }
  @media (max-width: 1608px) {
    height: 58vh;
  }
  @media (max-width: 1508px) {
    height: 55vh;
  }
  @media (max-width: 1308px) {
    height: 52vh;
  }
`;
